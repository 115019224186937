import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";

import projImg1 from "../assets/img/barreldungeon2.png";
import projImg2 from "../assets/img/rtype.png";
import projImg3 from "../assets/img/happylungs.png";
import projImg4 from "../assets/img/hackernews-client.jpg";
import projImg5 from "../assets/img/hackernews-server.jpg";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Barrel Dungeon 2",
      description: "University project where we developed a great 3D game based in 3D Dot Game Heroes with Unity.",
      technologies: ["Unity", "C#"],
      imgUrl: projImg1,
      git: "https://github.com/IvanJimenoRamirez/3D-Dot-Game",
    },
    {
      title: "R-Type",
      description: "University project where we had to replicate the R-Type arcade game in C++.",
      technologies: ["C++", "OpenGL"],
      imgUrl: projImg2,
      git: "https://github.com/juliahc/RType",
    },
    {
      title: "Happy Lungs",
      description: "University project where we build an app that shows a map with the levels of contamination in Catalonia in real time and permit to share and save specific locations.",
      technologies: ["React Native", "NodeJS", "Express" , "MongoDB"],
      imgUrl: projImg3,
      git: "https://github.com/HappyLungs/PES_HappyLungs",
    },
    {
      title: "Hacker News (client-side)",
      description: `University project where we created a client-side web based on the web "Hacker News" using React and an API developed in the previous project (Hacker News (server-side))`,
      technologies: ["React", "NodeJS", "Express" , "MongoDB"],
      imgUrl: projImg4,
      git: "https://github.com/polkp22/ASW_Hacker_News_cwr",
    },
    {
      title: "Hacker News (server-side)",
      description: `University project where we cloned the famouse web "Hacker News" with JavaScript and MongoDB as a server-side web.`,
      technologies: ["NodeJS", "Express", "MongoDB"],
      imgUrl: projImg5,
      git: "https://github.com/juliahc/ASW_Hacker_News",
    }
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Showcasing My Abilities: A Collection of My Most Significant Projects</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">University projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Personal projects</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row className="justify-content-around">
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>Coming soon! Stay tuned for new updates.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
