import 'react-multi-carousel/lib/styles.css';

import { Row, Col } from "react-bootstrap";
import Collapsible from 'react-collapsible';
import arrow1 from "../assets/img/arrow1.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx zoomIn">
                        <h2>Skills</h2>
                        <p className="sectionSubtitle">
                          Demonstrating Technical Proficiency: A Display of My Core Competencies
                        </p>
                        <Row>
                            <Col className="col-md-6 d-flex justify-content-center">
                              <Collapsible className="skillCollapse" trigger={<div className="skillCollapseTitle"><span>Programming languages</span><img className="skillArrow" src={arrow1} alt='arrow'></img></div>}>
                                <br/>
                                <span className="skillLevel">Advanced Skills</span>
                                <div>
                                  <div>C++</div>
                                  <div>Javascript</div>
                                  <div>Java</div>
                                  <div>MongoDB (MQL)</div>
                                  <div>PHP</div>
                                </div>
                                <br/>
                                <span className="skillLevel">Intermediate</span>
                                <div>
                                  <div>C</div>
                                  <div>C#</div>
                                  <div>Haskell</div>
                                  <div>Python</div>
                                  <div>Smalltalk</div>
                                  <div>SQL</div>
                                </div>
                                <br/>
                                <span  className="skillLevel">Familiar</span>
                                <div>
                                  <div>Assembly</div>
                                  <div>Cypher</div>
                                </div>
                              </Collapsible>
                            </Col>
                            <Col className="col-md-6 d-flex justify-content-center">
                              <Collapsible className="skillCollapse" trigger={<div className="skillCollapseTitle"><span>Miscellaneous</span><img className="skillArrow" src={arrow1} alt='arrow'></img></div>}>
                                <br/>
                              <span className="skillLevel">Advanced Skills</span>
                                <div>
                                  <div>CSS</div>
                                  <div>HTML</div>
                                </div>
                                <br/>
                                <span className="skillLevel">Intermediate</span>
                                <div>
                                  <div>Markdown</div>
                                  <div>Unity</div>
                                </div>
                                <br/>
                                <span className="skillLevel">Familiar</span>
                                <div>
                                  <div>OpenGL</div>
                                  <div>LaTex</div>
                                </div>
                              </Collapsible>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col className="col-md-6 d-flex justify-content-center">
                              <Collapsible className="skillCollapse" trigger={<div className="skillCollapseTitle"><span>Frameworks</span><img className="skillArrow" src={arrow1} alt='arrow'></img></div>}>
                                <br/>
                                <span className="skillLevel">Advanced Skills</span>
                                <div>
                                  <div>SpringBoot</div>
                                  <div>Codeigniter</div>
                                  <div>Express JS</div>
                                </div>
                                <br/>
                                <span className="skillLevel">Intermediate</span>
                                <div>
                                  <div>React</div>
                                  <div>React Native</div>
                                </div>
                                <br/>
                                <span className="skillLevel">Familiar</span>
                                <div>
                                  <div>Astro</div>
                                  <div>Vue</div>
                                </div>
                              </Collapsible>
                            </Col>
                            <Col className="col-md-6 d-flex justify-content-center">
                              <Collapsible className="skillCollapse" trigger={<div className="skillCollapseTitle"><span>Other Systems</span><img className="skillArrow" src={arrow1} alt='arrow' ></img></div>}>
                                <br/>
                              <span className="skillLevel">Advanced Skills</span>
                                <div>
                                  <div>MacOS</div>
                                  <div>Windows</div>
                                  <div>Git</div>
                                  <div>Git Flow</div>
                                  <div>Visual Studio Code</div>
                                </div>
                                <br/>
                                <span className="skillLevel">Intermediate</span>
                                <div>
                                  <div>AWS (EC2, S3, Beanstalk, ... )</div>
                                  <div>Android Studio</div>
                                  <div>BIOS</div>
                                  <div>IntelliJ</div>
                                  <div>Linux</div>
                                  <div>Microsoft Office 365</div>
                                  <div>Visual Studio</div>
                                </div>
                              </Collapsible>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt='Background blur' />
    </section>
  )
}
