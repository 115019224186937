import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/githubIcon.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="align-items-center">
          <div className="text-center">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/iv%C3%A1n-jimeno-ram%C3%ADrez-1ab450202/" target="_blank" rel="noopener noreferrer" ><img src={navIcon1} alt="" /></a>
              <a href="https://github.com/IvanJimenoRamirez" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="" /></a>
              <a href="mailto:ivan.jimeno.ramirez1@gmail.com" target="_blank" rel="noopener noreferrer"><img className="whiteFilter" src={navIcon3} alt="" /></a>
            </div>
            <p>&copy;Copyright {(new Date().getFullYear())} All Rights Reserved</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}
