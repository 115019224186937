import { Col } from "react-bootstrap";
import GitHubIcon from '../assets/img/githubIcon.svg';

export const ProjectCard = ({ title, description, technologies, imgUrl, git }) => {
  return (
    <Col size={12} sm={12} md={5} className="proj d-flex flex-column align-items-center">
      <div className="proj-imgbx">
        <img src={imgUrl}  alt={`Project named: ${title}`}/>
      </div>
      <div className="proj-description">
        <h6>{title}</h6>
        <span>
          {description}
        </span>
        <div>
          <span>Technologies used:</span>
          <ul className="proj-tech">
            {technologies.map((tech, index) => (
              <li key={index}>{tech}</li>
            ))}
          </ul>
        </div>

      </div>
      <div className="proj-github">
        <a href={git} target="_blank" >Github Repo <img src={GitHubIcon}></img></a>
      </div>
    </Col>
  )
}
