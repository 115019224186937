import { Container, Row, Col } from "react-bootstrap";
import ArrowDown from "../assets/img/box-arrow-down.svg";
import React from "react";

export const AboutMe = () => {
    
    let handleClick = (type) => {
        let showtype = type.split("Btn")[0];
        let otherType = type === "educationBtn" ? "workBtn" : "educationBtn";
        let otherShowType = type === "educationBtn" ? "work" : "education";

        document.getElementById(type).classList.add("qualification__active");
        document.getElementById(otherType).classList.remove("qualification__active");
        document.getElementById(showtype).classList.add("qualification__active");
        document.getElementById(otherShowType).classList.remove("qualification__active");
    }

    return (
        <section className="about">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="aboutWrapper">
                            <h2 id="about">About Me</h2>
                            <span className="sectionSubtitle">Getting to Know Me: An Introduction to My Background</span>
                            <p className="aboutContent">
                                I was born and raised in Barcelona on July 3, 2001 and I am currently working as a Full-Stack developer in SeniorDomo. I've finished the degree in Informatics Engineering at the Universitat Politècnica de Catalunya (UPC) at the end of 2023. While in school, I have gained valuable real-world experience through my work as a full-stack developer at Invoport and Techclass Academy, where I have honed my skills and honed my ability to work effectively in a team environment over the past two years. My experience in server-side applications and my natural aptitude for problem-solving have been invaluable assets in my career thus far. I am highly motivated to continue learning and growing in the software industry, and am constantly seeking out new challenges to expand my capabilities.
                            </p>
                            <span className="sectionSubtitle">In summary</span> 
                            <ul>
                                <li><h5> Acquired 2 years of experience as a full-stack developer </h5></li>
                                <li><h5> Developed strong teamwork and problem-solving skills </h5></li>
                                <li><h5> Committed to expanding capabilities and taking on new challenges </h5></li>
                            </ul>
                            <a className="aboutMeCV aboutContent" target="_blank" href="./ivanCV.pdf">
                                Download my CV <img src={ArrowDown}></img>
                            </a>
                            <span className="sectionSubtitle">A Timeline of My Educational and Professional Development</span>

                            <div className="qualification__tabs">
                                <div className="qualification__button button--flex qualification__active" id="educationBtn" data-target="#education" onClick={() => handleClick("educationBtn")}>
                                    <i className="uil uil-graduation-cap qualification__icon"></i>
                                    Education
                                </div>
                                <div className="qualification__button button--flex" data-target="#work" id="workBtn" onClick={() => handleClick("workBtn")}>
                                    <i className="uil uil-briefcase-alt qualification__icon"></i>
                                    Work
                                </div>
                            </div>
                            <section className="qualification section" id="qualification">
                                <div className="qualification__container container w-100">
                                    <div className="qualification__sections">
                                        <div className="qualification__content qualification__active" data-content id="education">
                                            <div className="qualification__data">
                                                <div>
                                                    <h3 className="qualification__title">Computer Science Degree</h3>
                                                    <span className="qualification__subtitle">Universitat Politècnica de Catalunya, FIB</span>
                                                    <div className="qualification__calendar">
                                                        <i className="uil uil-calendar-alt"></i>
                                                        2019 - june 2023
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="qualification__rounder"></span>
                                                    <span className="qualification__line"></span>
                                                </div>
                                            </div>
                                            <div className="qualification__data">
                                                <div></div>
                                                <div>
                                                    <span className="qualification__rounder"></span>
                                                </div>
                                                <div>
                                                    <h3 className="qualification__title">HTML, CSS & JavaScript Course</h3>
                                                    <span className="qualification__subtitle">JEDI Academy</span>
                                                    <div className="qualification__calendar">
                                                        <i className="uil uil-calendar-alt"></i>
                                                        2021
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="qualification__content" data-content id="work">
                                            <div className="qualification__data">
                                                <div></div>
                                                <div>
                                                    <span className="qualification__rounder"></span>
                                                    <span className="qualification__line"></span>
                                                </div>
                                                <div>
                                                    <h3 className="qualification__title">Full-Stack developer</h3>
                                                    <span className="qualification__subtitle">SeniorDomo, S.L.</span>
                                                    <span className="qualification__subtitle">Working as a Full-Stack developer.</span>
                                                    <div className="qualification__calendar">
                                                        <i className="uil uil-calendar-alt"></i>
                                                        mar. 2023 - present
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="qualification__data">
                                                <div>
                                                    <h3 className="qualification__title">Data Management Developer Intern</h3>
                                                    <span className="qualification__subtitle">Invoport, S.L.</span>
                                                    <span className="qualification__subtitle">Working as a Full-Stack developer in different web application projects and server side configurations.</span>
                                                    <div className="qualification__calendar">
                                                        <i className="uil uil-calendar-alt"></i>
                                                        nov. 2022 - mar. 2023
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="qualification__rounder"></span>
                                                    <span className="qualification__line"></span>
                                                </div>
                                            </div>
                                            <div className="qualification__data">
                                                <div></div>
                                                <div>
                                                    <span className="qualification__rounder"></span>
                                                </div>
                                                <div>
                                                    <h3 className="qualification__title">Software Engineer</h3>
                                                    <span className="qualification__subtitle">TechClass Academy, S.L</span>
                                                    <span className="qualification__subtitle">Working as a Full-Stack developer in a server side web application using PHP, NodeJS and MongoDB.</span>
                                                    <div className="qualification__calendar">
                                                        <i className="uil uil-calendar-alt"></i>
                                                        june 2021 - nov. 2022
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
